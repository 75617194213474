import { useState } from "react";
import { Link } from "@remix-run/react";
import { CalendarIcon, GiftIcon } from "lucide-react";

export type EventPrice = {
  id: string;
  name: string;
  pricePerPerson: number;
  total?: number;
  specialFeature?: string;
  features: string[];
  link: string;
  giftLink: string | null;
};
export type Forfait = {
  code: string;
  name: string;
  duration: string;
  cssClass: string;
  prices: EventPrice[];
};
export type PriceTableProps = {
  forfait: Forfait;
};

export const PackagePriceTable = ({ forfait }: PriceTableProps) => {
  const [selectedPrice, setSelectedPrice] = useState<EventPrice>(
    forfait.prices[0]!,
  );

  return (
    <div
      className={`mb-8 flex w-full flex-wrap items-center gap-4 overflow-hidden rounded bg-card p-4 shadow sm:p-8 lg:gap-0 ${forfait.cssClass ? forfait.cssClass : ""}`}
    >
      <div className="w-full self-start lg:w-1/5">
        <h3
          className="mb-4 font-heading text-3xl font-bold"
          dangerouslySetInnerHTML={{ __html: forfait.name }}
        />
        <div className="flex flex-row justify-between gap-2 self-start lg:gap-1">
          {forfait.prices.map((price) => (
            <button
              key={price.id}
              className={`flex-1 rounded-lg border-2 border-black px-3 py-1 ${
                selectedPrice.id === price.id
                  ? "bg-pink-600 text-white"
                  : "bg-white text-black"
              }`}
              onClick={() => setSelectedPrice(price)}
            >
              {price.name}
            </button>
          ))}
        </div>
      </div>
      <div className="w-full px-3 lg:w-2/5">
        <ul className="mb-4 ml-5 list-image-[url('data:image/svg+xml;base64,PHN2ZwogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTggMTgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIKICAgIGZpbGw9ImN1cnJlbnRDb2xvciI+CiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiM3OWI5N2UiCiAgICAgICAgZD0iTTEwIDE4YTggOCAwIDEwMC0xNiA4IDggMCAwMDAgMTZ6bTMuNzA3LTkuMjkzYTEgMSAwIDAwLTEuNDE0LTEuNDE0TDkgMTAuNTg2IDcuNzA3IDkuMjkzYTEgMSAwIDAwLTEuNDE0IDEuNDE0bDIgMmExIDEgMCAwMDEuNDE0IDBsNC00eiIKICAgICAgICBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjwvcGF0aD4KPC9zdmc+')] text-sm text-muted-foreground">
          {selectedPrice.specialFeature && (
            <li className="mb-4">
              <span className="in animate-[wiggle_2s_ease-in-out_infinite] font-bold">
                <span className="bg-gradient-to-r from-blue-400 via-pink-500 to-purple-500 bg-clip-text text-transparent">
                  Offre spéciale:
                </span>{" "}
                <span className="text-primary">
                  {selectedPrice.specialFeature}
                </span>
              </span>
            </li>
          )}
          {selectedPrice.features.map((feature, index) => (
            <li className="mb-4" key={index}>
              <span dangerouslySetInnerHTML={{ __html: feature }} />
            </li>
          ))}
        </ul>
      </div>
      <div className="flex h-32 w-full flex-col justify-center lg:h-full lg:w-44">
        {forfait.prices.map((price, index) => (
          <div
            className={`duration-1500 flex flex-col items-center justify-center overflow-hidden transition-all ease-in lg:text-center ${
              price.id === selectedPrice.id ? "h-full w-full px-3" : "h-0 w-0"
            }`}
            key={`big price-${index}`}
          >
            <div className="flex items-center justify-center">
              <span className="text-4xl font-bold">
                {price.pricePerPerson}€<small className="text-sm">/pers</small>
              </span>
            </div>

            {price.total ? (
              <span className="text-sm text-muted-foreground">
                <strong>{price.total}€</strong> total
              </span>
            ) : (
              <span className="text-sm text-muted-foreground"> - </span>
            )}
          </div>
        ))}
      </div>
      <div className="flex w-full flex-row justify-center gap-4 px-3 lg:h-full lg:w-1/5 lg:flex-col">
        <Link
          className="mt-4 inline-block flex-1 rounded-l-xl rounded-t-xl border-2 border-pink-600 bg-pink-600 px-3 py-2 text-center font-bold leading-loose text-white no-underline transition duration-200 hover:bg-pink-700 sm:flex-none lg:mt-0 lg:px-6"
          to={selectedPrice.link}
          title={`Réserver ${forfait.name} en ligne`}
        >
          <CalendarIcon className="mb-1 mr-1 inline-block h-6 w-6" />
          Réserver
        </Link>
        {selectedPrice.giftLink && (
          <a
            className="mt-4 inline-block flex-1 rounded-l-xl rounded-t-xl border-2 border-muted-foreground px-3 py-2 text-center font-bold leading-loose text-muted-foreground no-underline transition duration-200 hover:border-primary hover:text-primary sm:flex-none lg:mt-0 lg:px-6"
            href={selectedPrice.giftLink}
            target="_blank"
            title={`Acheter carte cadeau ${forfait.name}`}
            rel="noreferrer"
          >
            <GiftIcon className="mb-1 mr-1 inline-block h-6 w-6" />
            Cadeau *
          </a>
        )}
      </div>
    </div>
  );
};
